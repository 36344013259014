import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__bg app__wrapper section__padding" id="about">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.bg2} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="Our History" />
      <h1 className="headtext__cormorant">About Us</h1>

      <div className="app__aboutus-content">
        <div className="app__aboutus-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="p__opensans">At Soothing Glance, we are passionate about enhancing your well-being. Founded by certified fitness trainer and massage expert Sandeep Mishra, our mission is to provide you with the ultimate relaxation and wellness experience.</p>
        </div>
        <p className="p__opensans"> With a dedicated team of skilled therapists and a commitment to holistic health, we invite you to embark on a journey of self-care and rejuvenation. </p>
      </div>

      {/* <div className="app__aboutus-sign">
        <p>Sandeep Mishra</p>
        <p className="p__opensans">Fitness Trainer & Massage Therapist</p>
        <p className="p__opensans">Founder & CEO, Soothing Glance</p>
        <img src={images.sign} alt="sign_image" />
      </div> */}
    </div>
  </div>
);

export default AboutUs;
