import React from 'react';
import { FiInstagram } from 'react-icons/fi';

// import { FooterOverlay } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    {/* <FooterOverlay /> */}
    {/* <Newsletter /> */}

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">+91 9619 510441</p>
        <p className="p__opensans">+91 7977 252528</p>
      </div>

      <div className="app__footer-links_logo">
        <h1 className="text-white">Soothing Glance</h1>
        <p className="p__opensans">&quot;The best way to find yourself is to lose yourself in the service of others.&quot;</p>
        <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <a href="https://www.instagram.com/soothingglance/" aria-label="Instagram"><FiInstagram /></a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Monday-Saturday:</p>
        <p className="p__opensans">12:00 pm - 04:00 pm</p>
        <p className="p__opensans">Sunday:</p>
        <p className="p__opensans">06:00 am - 08:00 pm</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2023 - 2024 Soothing Glance. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;
