import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';

const FindUs = () => (
  <div className="find__us app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Contact" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>
        Find Us
      </h1>
      <div className="app__wrapper-content">
        <p
          className="p__cormorant"
          style={{ color: '#DCCA87', margin: '2rem 0' }}
        >
          Call Us
        </p>
        <p className="p__opensans" style={{ fontSize: '20px' }}>+91 9619 510441</p>
        <p className="p__opensans" style={{ fontSize: '20px' }}>+91 7977 252528</p>
        <p
          className="p__cormorant"
          style={{ color: '#DCCA87', margin: '2rem 0' }}
        >
          Working Hours
        </p>
        <p className="p__opensans">Mon - Sat: 12:00 pm - 04:00 pm</p>
        <p className="p__opensans">Sun: 06:00 am - 09:00 pm</p>
      </div>
      {/* <button type="button" className="custom__button" style={{ marginTop: '2rem' }}>Visit Us</button> */}
    </div>

    <div className="app__wrapper_img">
      <img src={images.contact} alt="finus_img" />
    </div>
  </div>
);

export default FindUs;
