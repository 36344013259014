import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="Get the Haven like Relaxation at your Home" />
      <h1 className="app__header-h1">Welcome to Soothing Glance</h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>Your Haven of Relaxation and Rejuvenation. Discover a World of Tranquility Through Expert Massages and Spa Therapies. Indulge in the Blissful Escape You Deserve </p>
      <a href="#menu" type="button" className="custom__button">Explore Menu</a>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header_img" />
    </div>
  </div>
);

export default Header;
