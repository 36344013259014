import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding" id="masseur">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.sandeep} alt="Sandeep_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="Masseur's word" />
      <h1 className="headtext__cormorant">What we believe in</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="p__opensans">As the heart and soul of Soothing Glance, I truly believe that true wellness comes from within. As a certified fitness trainer and massage expert, I combine my expertise to create tailored experiences that promote both physical and mental harmony.</p>
        </div>
        <p className="p__opensans"> With a deep understanding of the body and its needs, my team and I offer a wide range of massage therapies designed to soothe, heal, and rejuvenate. </p>
      </div>

      <div className="app__chef-sign">
        <p>Sandeep Mishra</p>
        <p className="p__opensans">Fitness Trainer & Massage Therapist</p>
        <p className="p__opensans">Founder & CEO, Soothing Glance</p>
        {/* <img src={images.sign} alt="sign_image" /> */}
      </div>
    </div>
  </div>
);

export default Chef;
