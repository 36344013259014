import images from './images';

const massageServices = [
  {
    title: 'Deep Tissue Massage',
    price: '₹1500',
    tags: 'Gun Massage, oil Massage | 100 min',
  },
  {
    title: 'IASTM Massage',
    price: '₹1500',
    tags: 'Gun Massage, Instrument Massage | 120 min',
  },
  {
    title: 'Swedish Massage',
    price: '₹1200',
    tags: 'Gun Massage, Oil Massage | 90 min',
  },
  {
    title: 'Thai Foot Massage',
    price: '₹1000',
    tags: 'Full Body Gun Massage, Oil Massage Lower Body | 60 min',
  },
  {
    title: 'Foot Reflexology',
    price: '₹1000',
    tags: 'Full Body Gun Massage, Oil Massage Lower Body | 60 min',
  },
];

const otherServices = [
  {
    title: 'Gun Massage',
    price: '₹700',
    tags: 'Full  Body Massage | 40min',
  },
  {
    title: 'Head Massage',
    price: '₹300',
    tags: 'oil Massage | 15 min',
  },
];

const awards = [
  {
    imgUrl: 'images.award02',
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

const instagram = [
  'https://www.instagram.com/p/C-XcoSiMDI3/?utm_source=ig_embed&amp;utm_campaign=loading',
  'https://www.instagram.com/p/C_ADiGostpY/?utm_source=ig_embed&amp;utm_campaign=loading',
  'https://www.instagram.com/reel/C-zGBF1ppMU/?utm_source=ig_embed&amp;utm_campaign=loading',
  'https://www.instagram.com/reel/C-jqNgWtGDP/?utm_source=ig_embed&amp;utm_campaign=loading',
  'https://www.instagram.com/reel/C-rU_TXN_OF/?utm_source=ig_embed&amp;utm_campaign=loading',
];

const testimonials = [
  {
    body: 'Had an amazing mobile massage session with Sandeep Sir! His soothing touch and calm demeanor instantly put me at ease. The convenience of having the massage at home made it even more relaxing. Highly recommended!',
    author: {
      name: 'Rohit Sharma',
      handle: 'Fitnes Trainer, Trio Fitness',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541',
    },
  },
  {
    body: 'The mobile massage by Sandeep Sir was exactly what I needed! His soothing glance and skilled hands worked wonders, and the fact that I could enjoy it in the comfort of my home was fantastic. Grateful for the wonderful experience!',
    author: {
      name: 'Neha Verma',
      handle: 'nehaverma',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541',
    },
  },
  {
    body: 'I had an incredible mobile massage session with Sandeep Sir. His soothing glance and expert techniques provided much-needed relief, and the convenience of having it at home made it even more special. I left feeling renewed and invigorated!',
    author: {
      name: 'Anita Singh',
      handle: 'anitasingh',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541',
    },
  },
  {
    body: "Sandeep Sir's mobile massage is a blissful experience! His expertise and attention to detail make every session exceptional. I left feeling rejuvenated and relaxed. Thank you for bringing the spa experience to my home!",
    author: {
      name: 'Shivam Sharma',
      handle: 'Fitness Trainer, Trio Fitness',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541',
    },
  },
  {
    body: "Sandeep Sir's mobile massage is a true treat for the senses! The tranquil atmosphere and his calming presence, combined with the convenience of being at home, create a perfect environment for relaxation. A delightful experience!",
    author: {
      name: 'Chandan',
      handle: 'Fitness Trainer, Trio Fitness',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541',
    },
  },
  {
    body: "Sandeep Sir's mobile massage is a holistic experience! The soothing ambiance and his skilled hands, combined with the comfort of home, create a perfect harmony. I highly recommend his services for anyone seeking relaxation and rejuvenation without leaving home.",
    author: {
      name: 'Rahul Kapoor',
      handle: 'rahulkapoor',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541',
    },
  },
  // More testimonials...
];

export default { otherServices, massageServices, awards, testimonials, instagram };
