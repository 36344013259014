import bg from '../assets/bg.png';
import bg2 from '../assets/background-2.jpg';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/wellness-3688169.jpg';
import gallery02 from '../assets/wellness-3791764.jpg';
import gallery03 from '../assets/wellness-4635619.jpg';
import gallery04 from '../assets/background-3.jpg';
import knife from '../assets/knife.png';
import candlestick from '../assets/candlestick-82374-removebg-preview.png';
import logo from '../assets/logo.png';
import menu from '../assets/welcome.jpg';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/background.jpg';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import sandeep from '../assets/sandeep.jpg';
import contact from '../assets/contact.jpg';

export default {
  bg,
  bg2,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  contact,
  sandeep,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  candlestick,
};
